import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import headerImage from '../../../static/images/bridges.jpg';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { ADDRESS_LINK } from '../../../constants/links';
import { SMALL_THRESHOLD } from '../../../constants/media';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Helmet} from "react-helmet";

const classes = theme => ({
  card: {
    maxWidth: '900px',
    margin: 'auto',
    marginBottom: '62px',
    paddingTop: '62px'
  },
  media: {
    height: '350px'
  },
  mediaPhone: {
    height: '250px'
  },
  contactInputs: {
    width: '100%'
  },
  infoTable: {
    marginTop: 10
  },
  contactMessage: {
    maxWidth: '1000px'
  },
  // link: {
  // cursor: 'pointer'
  // },
  tableRow: {
    cursor: 'pointer'
    // color: 'white',
    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.main
    // }
  }
});

const scriptInner = '{ "@context": "https://schema.org",\n' +
  '              "@type": "Organization",\n' +
  '              "url": "http://www.giavaneers.com",\n' +
  '              "name": "Giavaneers, Inc.",\n' +
  '              "contactPoint": {\n' +
  '              "@type": "ContactPoint",\n' +
  '              "telephone": "831-459-9060",\n' +
  '              "contactType": "Information" } }';

class Contact extends Component {

  mailToInfo = () => {
    this.refs.mailInfo.click();
  };

  mailToJobs = () => {
    this.refs.mailJobs.click();
  };

  callPhone = () => {
    this.refs.phone.click();
  };

  render() {
    return (
      <Body
        mailToInfo={this.mailToInfo}
        mailToJobs={this.mailToJobs}
        callPhone={this.callPhone}
        {...this.props}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Giavaneers - Contact Us</title>
          <meta
            name="description"
            content="One of the best things about doing what we do is the great people we get to meet and interact
             with. Please feel free to get in touch anytime by phone or email or if you're in the area, just drop in
             and say hello." />
          <script type="application/ld+json">
            {scriptInner}
          </script>
        </Helmet>
        <a
          ref={'mailInfo'}
          href={'mailto:info@giavaneers.com'}
          style={{ display: 'none' }}
        >
          mailInfo
        </a>
        <a
          ref={'mailJobs'}
          href={'mailto:jobs@giavaneers.com'}
          style={{ display: 'none' }}
        >
          mailJobs
        </a>
        <a ref={'phone'} href={'tel:18314599060'} style={{ display: 'none' }}>
          phone
        </a>
      </Body>
    );
  }
}

function Body(props) {
  const { classes, goNavigate } = props;
  const matches = useMediaQuery(`(min-width:${SMALL_THRESHOLD})`);

  const infoTypographyVariant = matches ? 'h6' : 'subtitle1';

  return (
    <Card className={classes.card}>
      <CardMedia
        className={matches ? classes.media : classes.mediaPhone}
        image={`${headerImage}`}
        // title="Contact Us"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h5">
          Contact Us
        </Typography>
        <Typography
          className={classes.contactMessage}
          variant={'subtitle1'}
          component="p"
        >
          One of the best things about doing what we do is the great people we
          get to meet and interact with. Whether they be previous clients, or
          potential new ones, or people who just want to share an idea or talk
          about a new technology they've run across, it's great fun for us.
          Please feel free to get in touch anytime by phone or email or if
          you're in the area, just drop in and say hello.
        </Typography>
        <Table className={classes.infoTable}>
          <TableBody>
            <TableRow
              key={1}
              className={classes.tableRow}
              onClick={goNavigate(ADDRESS_LINK)}
            >
              <TableCell component="th" scope="row">
                <Typography variant={infoTypographyVariant}>Address</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  // className={classes.link}
                  // onClick={goNavigate(ADDRESS_LINK)}
                  color="primary"
                  variant={infoTypographyVariant}
                  component={infoTypographyVariant}
                >
                  1545 1/2 Pacific Ave. Santa Cruz, CA 95060
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              key={2}
              className={classes.tableRow}
              onClick={props.mailToInfo}
            >
              <TableCell component="th" scope="row">
                <Typography variant={infoTypographyVariant}>Info</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  color="primary"
                  // onClick={props.mailToInfo}
                  // className={props.classes.link}
                  // style={{display: matches ? undefined : 'none'}}
                  variant={infoTypographyVariant}
                  component={infoTypographyVariant}
                >
                  info@giavaneers.com
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              key={3}
              className={classes.tableRow}
              onClick={props.mailToJobs}
            >
              <TableCell component="th" scope="row">
                <Typography variant={infoTypographyVariant}>Jobs</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  color="primary"
                  // onClick={props.mailToJobs}
                  // className={props.classes.link}
                  // style={{display: matches ? undefined : 'none'}}
                  variant={infoTypographyVariant}
                  component={infoTypographyVariant}
                >
                  jobs@giavaneers.com
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              key={4}
              className={classes.tableRow}
              onClick={props.callPhone}
            >
              <TableCell component="th" scope="row">
                <Typography variant={infoTypographyVariant}>Phone</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  color="primary"
                  // onClick={props.callPhone}
                  // className={props.classes.link}
                  // style={{display: matches ? undefined : 'none'}}
                  variant={infoTypographyVariant}
                  component={infoTypographyVariant}
                >
                  +1 (831) 459-9060
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {props.children}
      </CardContent>
    </Card>
  );
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(classes)(Contact);
