import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PARTNERS } from '../../../constants/partners';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import PartnerDialog from './PartnerDialog';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    paddingBottom: '62px',
    paddingTop: '62px'
  },
  gridList: {
    maxWidth: '1400px'
  },
  avatarContainer: {
    display: 'flex',
    height: '120',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: theme.palette.secondary.main
    }
  },
  imgContainer: {
    height: '110px'
  },
  avatar: {
    margin: 2,
    height: '110px',
    padding: 10
  },
  avatarWide: {
    margin: 2,
    width: '240px',
    padding: 10
  },
  tileBar: {
    height: '36px',
    background: 'rgba(0, 0, 0, 0.2)'
  }
});

class PartnersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      openPartner: {}
    };
  }

  handleClickOpen = partner => () => {
    this.setState({
      dialogOpen: true,
      openPartner: partner
    });
  };

  handleClose = value => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const { classes, width } = this.props;

    let columns;
    if (width === 'xs') {
      columns = 1;
    }
    if (width === 'xs') {
      columns = 1;
    }
    if (width === 'sm') {
      columns = 2;
    }
    if (width === 'md') {
      columns = 3;
    }
    if (width === 'lg') {
      columns = 4;
    }
    if (width === 'xl') {
      columns = 4;
    }
    return (
      <div className={classes.root}>
        <GridList
          cellHeight={180}
          spacing={10}
          className={classes.gridList}
          cols={columns}
        >
          {PARTNERS.map(partner => (
            <GridListTile key={partner.logo} cols={1}>
              <div
                className={classes.avatarContainer}
                onClick={this.handleClickOpen(partner)}
              >
                <div className={classes.imgContainer}>
                  <img
                    alt={partner.title}
                    src={partner.logo}
                    className={
                      partner.hasWideLogo ? classes.avatarWide : classes.avatar
                    }
                  />
                </div>
              </div>
            </GridListTile>
          ))}
        </GridList>
        <PartnerDialog
          {...this.props}
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          partner={this.state.openPartner}
        />
      </div>
    );
  }
}

PartnersList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  withWidth()
)(PartnersList);
