import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import NavMenu from './NavMenu';
import IconButton from '@material-ui/core/IconButton';
import { HOME, PAGES } from '../../../constants/pages';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { SMALL_THRESHOLD } from '../../../constants/media';
import GiavaneersIcon from '../../../static/images/GiavaneersMark.png';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  tabscontainer: {
    width: '100%'
  },
  tabRoot: {
    color: 'white',
    fontSize: '.9em',
    opacity: 1,
    '&:hover': {
      color: theme.palette.secondary.main,
      opacity: 1
    }
  },
  tabSelected: {
    color: theme.palette.secondary.main
  }
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.props.history.listen(location => {
      const currentPageList = PAGES.filter(
        page => page.route === location.pathname
      );
      const currentPage =
        currentPageList.length === 0 ? undefined : currentPageList[0];
      this.setState({ currentPage: currentPage });
    });
  }

  state = {
    currentPage:
      PAGES.filter(page => page.route === this.props.location.pathname)
        .length === 0
        ? undefined
        : PAGES.filter(page => page.route === this.props.location.pathname)[0]
  };

  handleChange = (event, value) => {
    this.setState({ value });
    const newPage = PAGES.filter(page => page.index === value);
    this.props.goRedirect(newPage[0].route)();
  };

  render() {
    const value = this.state.currentPage
      ? this.state.currentPage.index
      : undefined;
    const { classes } = this.props;
    const tabList = PAGES.filter(page => page.title !== 'Home').map(
      ({ route, title }) => (
        <LinkTab {...this.props} label={title} key={route} route={route} />
      )
    );

    return (
      <div>
        <AppBar position="fixed" color="primary">
          <NavToolbar
            handleChange={this.handleChange}
            currentPage={this.state.currentPage}
            value={value}
            classes={classes}
            {...this.props}
          >
            {tabList}
          </NavToolbar>
        </AppBar>
      </div>
    );
  }
}

function NavToolbar(props) {
  const isPhone = !useMediaQuery(`(min-width:${SMALL_THRESHOLD})`);
  const { handleChange, value, classes, goRedirect } = props;
  return (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Home"
        onClick={(event) => {
          event.preventDefault();
          goRedirect(HOME.route)();
        }}
        component="a"
        href={`${window.location.protocol}//${window.location.host}${HOME.route}`}

      >
        <img src={GiavaneersIcon} width="140px" alt={''} />
      </IconButton>
      {isPhone && <div className={classes.grow} />}
      {isPhone && <NavMenu {...props} />}
      {!isPhone && (
        <Tabs
          className={classes.tabscontainer}
          variant="fullWidth"
          value={value}
          onChange={handleChange}
        >
          {props.children}
        </Tabs>
      )}
    </Toolbar>
  );
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      href={`${window.location.protocol}//${window.location.host}${props.route}`}
      className={props.classes.tabRoot}
      onClick={event => event.preventDefault()}
      {...props}
    />
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  goRedirect: PropTypes.func.isRequired,
  index: PropTypes.number
};

export default withRouter(withStyles(styles)(NavBar));
