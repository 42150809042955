import ecoactImage from '../static/images/partners/Ecoact.png';
import boeingImage from '../static/images/partners/Boeing_full_logo.svg';
import adobeImage from '../static/images/partners/Adobe_logo_and_wordmark_(2017).svg';
import appleImage from '../static/images/partners/Apple-Apple.svg';
// import pacificBellImage from '../static/images/partners/Pac-Bell.png';
import attImage from '../static/images/partners/AT&T_logo_2016.svg';
import geImage from '../static/images/partners/General_Electric_logo.svg';
import ericssonImage from '../static/images/partners/Ericsson_logo.svg';
import usgsImage from '../static/images/partners/USGS_logo_green.svg';
import octolyticsImage from '../static/images/partners/octolytics.png';
import cornamiImage from '../static/images/partners/cornami_logo_h_600px.jpg';
import leonovusImage from '../static/images/partners/leonovus.png';
import symantecImage from '../static/images/partners/Symantec_logo10.svg';

import ditechImage from '../static/images/partners/Nuance_Communications_logo_2018.svg';
import digitalReflectionImage from '../static/images/partners/Dig_Refl_logo.png';
import sensoryImage from '../static/images/partners/sensoryscience.png';
// import argoImage from '../static/images/partners/argo-systems-llc-logo.PNG';
import imageImage from '../static/images/partners/imagesnap.png';

import dascomImage from '../static/images/partners/IBM_logo.svg';

import soflinkImage from '../static/images/partners/inchorus.gif';
import councilImage from '../static/images/partners/California_Judicial_Council_seal.png';
import resonateImage from '../static/images/partners/resonate.png';

export const PARTNERS = [
  {
    title: 'Ecology Action',
    description:
      "Ecology Action is one of the nation's oldest and most respected " +
      'organizations working with utilities, communities, and local and ' +
      'federal government to deliver programs that create positive change ' +
      'for the environment. Giavaneers is creating an entirely new cloud-based ' +
      "platform to replace most of Ecology Action's enterprise toolset " +
      'currently implemented with Salesforce. It is also developing a suite ' +
      'of new desktop and mobile apps to work with the new platform, targeting ' +
      'both internal staff as well as external partners.',
    logo: ecoactImage,
    url: 'https://ecoact.org/'
  },
  {
    title: 'Boeing',
    description:
      'The Boeing Company is an acknowledged leader in Aerospace technology. ' +
      'One particularly interesting project during our multi-faceted ' +
      'partnership was the design and implementation of a remotely-accessible ' +
      'network research testbed supporting user-defined models of various ' +
      'subsystems of the Teledesic Satellite System.',
    descriptionTwo:
      'ARGOSystems, an innovator in adaptive signal processing and high speed ' +
      'telecommunications, has been a development partner for several years. ' +
      'Our most recent collaboration is the newly released OC12 Fiber-Optic ' +
      'Protocol Analyzer, an OEM product for Radcom.',
    logo: boeingImage,
    url: 'https://www.boeing.com/',
    hasWideLogo: true
  },
  {
    title: 'Adobe',
    description:
      'Adobe is a leader in Graphics and Desktop Publishing Software. ' +
      "Giavaneers' staff in association with our affiliate Crosswise " +
      'Corporation developed Trapeze, a cross-platform, multimedia ' +
      'teleconferencing product extending Acrobat technology across the Internet.',
    logo: adobeImage,
    url: 'https://www.adobe.com/',
    hasWideLogo: true
  },
  {
    title: 'Apple',
    description:
      'Apple also partnered with Crosswise and Adobe to develop Trapeze. ' +
      "Earlier, it joined with Pacific Bell in Crosswise's initial product " +
      "effort: the world's first, cross-platform, realtime teleconferencing " +
      'application, FacetoFace.',
    logo: appleImage,
    url: 'https://www.apple.com/'
  },
  {
    title: 'Dascom',
    description:
      'Dascom is a leading provider of scalable intranet security and ' +
      'management solutions. Giavaneers participated in the IVCorba implementation.',
    logo: dascomImage,
    url: 'https://www.ibm.com'
  },
  {
    title: 'AT&T',
    description:
      'AT&T (known then as Pacific Bell) was part of the initial partnership (along with Apple ' +
      'Computer and Crosswise) which developed the award-winning, cross-platform, ' +
      'teleconferencing application, FacetoFace.',
    logo: attImage,
    url: 'https://en.wikipedia.org/wiki/Pacific_Bell'
  },
  {
    title: 'GE Energy',
    description:
      'GE Energy helps businesses and authorities that generate, ' +
      'transmit or use electricity. They work in all areas of the ' +
      'energy industry including coal, oil, natural gas and nuclear energy, ' +
      'as well as with renewable resources. Giavaneers took the lead in ' +
      'developing a 24/7 critical path system working to optimize coal blends ' +
      'in realtime for coal fired power plants in the Ohio River Valley, for ' +
      'which it won acclaim from First Energy for delivering their most ' +
      'effective contract fulfillment ever.',
    logo: geImage,
    url: 'https://www.ge.com/'
  },
  {
    title: 'Ericsson',
    description:
      'Ericsson is a world-leading supplier in telecommunications and data ' +
      'communications. We participated in the development of a cutting-edge ' +
      'Windows phone system - CyberGenie.',
    logo: ericssonImage,
    url: 'https://www.ericsson.com/en'
  },
  {
    title: 'USGS',
    description:
      'United States Geological Survey is a world class earth science ' +
      'organization. We feel honored to be in partnership to provide a ' +
      'data management application for this premier research organization.',
    logo: usgsImage,
    url: 'https://www.usgs.gov/'
  },
  {
    title: 'Octolytics',
    description:
      "Octolytics is a startup creating one of the world's first systems " +
      'for collecting realtime analytics of mobile phone data. Giavaneers ' +
      'was responsible for the architecture and development of the first ' +
      'product version of the entire system, including a novel, highly ' +
      'scalable cloud-based server capable of supporting more than 4 million ' +
      'user phones simultaneously on a single backend instance.',
    logo: octolyticsImage,
    url: 'http://www.octolytics.com/'
  },
  {
    title: 'LeoNovus',
    description:
      'LeoNovus is developing an innovative high performance browser-based ' +
      'Internet platform with a managed system approach that provides HDTVs and ' +
      'other consumer electronic devices with unprecedented access to all the ' +
      'features, content, and functionality available on the Web without the ' +
      'malware, security and support issues of a PC.',
    logo: leonovusImage,
    url: 'https://www.leonovus.com/',
    hasWideLogo: true
  },
  {
    title: 'Symantec',
    description:
      'Symantec is a global leader in infrastructure software, enabling ' +
      'businesses and consumers to have confidence in a connected world. ' +
      'The company helps customers protect their infrastructure, information, ' +
      'and interactions by delivering software and services that address ' +
      'risks to security, availability, compliance, and performance.',
    logo: symantecImage,
    url: 'https://www.symantec.com/',
    hasWideLogo: true
  },
  {
    title: 'Ditech Communications',
    description:
      'Ditech Communications (acquired by Nuance Communications in 2012) was a leader in the development of communications ' +
      'infrastructure equipment. Giavaneers contributed to the digital signal ' +
      'echo cancellation product line with embedded bus development across ' +
      'three operating systems as well as system level testbed development ' +
      'with a drag and drop UI controlling several T1 generators and switches.',
    logo: ditechImage,
    hasWideLogo: true
  },
  {
    title: 'Judicial Council of California',
    description:
      'The Judicial Council of California Appellate Court Case management ' +
      'system allows the public access to court records. Giavaneers has ' +
      'brought the first Appellate District online.',
    logo: councilImage
  },
  {
    title: 'Cornami',
    description:
      'Cornami technology enables the creation of a new category of ' +
      'applications that can utilize all available processing cores, ' +
      'communications links and storage. Processing units harnessed to ' +
      "execute applications include local and remote CPU's, GPU's and cloud " +
      'based services. Giavaneers has a leadership role in the architectural ' +
      'design and implementation of the Cornami framework.',
    logo: cornamiImage,
    url: 'http://cornami.com/',
    hasWideLogo: true
  },
  {
    title: 'Digital Reflection',
    description:
      'Digital Reflection is an innovator in the convergent ' +
      'television and computer monitor market. Giavaneers, in ' +
      'affiliation with OpenHouse Inc., has developed an embedded ' +
      'system application framework that is intrinsic to the Digital ' +
      'Reflection convergent television product. This product combines HDTV ' +
      'functions with DVD, CDROM, MP3, internet radio, e-mail, and consolidated ' +
      'shopping in a revolutionary dynamic interface.',
    logo: digitalReflectionImage,
    url: 'https://digitalreflectioncenter.com/',
    hasWideLogo: true
  },
  {
    title: 'Sensory Science',
    description:
      'Sensory Science is a high-end multimedia components manufacturer. ' +
      'Giavaneers, in affiliation with OpenHouse Inc., has developed an ' +
      "embedded system application framework for Sensory Science's multimedia " +
      'appliance product.',
    logo: sensoryImage
  },
  {
    title: 'ImageSnap',
    description:
      "ImageSnap's mission is to provide high-quality photo items, including " +
      'jigsaw puzzles, mouse pads, mugs, tiles and many more, via their Web ' +
      'site and a network of other Web sites.',
    logo: imageImage,
    url: 'https://www.imagesnap.com/',
    hasWideLogo: true
  },
  {
    title: 'Softlink InChorus',
    description:
      'Through their sophisticated compression technology, Softlink InChorus ' +
      'has set a new standard for multimedia email communication. Our Java ' +
      'multimedia player is a fundamental component of the InChorus technology.',
    logo: soflinkImage,
    hasWideLogo: true
  },
  {
    title: 'Resonate',
    description:
      'Resonate is a leader in server load balancing software. Giavaneers ' +
      'implemented a Java user interface for Resonate commander.',
    logo: resonateImage
  }
];
