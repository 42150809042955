export const THEME = {
  palette: {
    primary: {
      main: '#003973'
    },
    secondary: {
      main: '#bddbfc'
    },
    background: { main: '#98b5d3' },
    type: 'light'
  }
  // typography: { useNextVariants: true },
};
