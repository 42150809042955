import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { ADDRESS_LINK } from '../../../constants/links';
import { SMALL_THRESHOLD } from '../../../constants/media';

// TODO: use theme spacing instead of hardcoded px
const styles = theme => ({
  root: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '62px',
    backgroundColor: theme.palette.primary.main
  },
  text: {
    padding: '10px'
  },
  link: {
    padding: '10px',
    cursor: 'pointer',
    color: 'white',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
});

const typeVariant = 'subtitle2';

class Footer extends Component {
  mailTo = () => {
    this.refs.mailInfoFooter.click();
  };

  callPhone = () => {
    this.refs.phoneCallFooter.click();
  };

  render() {
    return (
      <FooterAll
        mailTo={this.mailTo}
        callPhone={this.callPhone}
        {...this.props}
      >
        <a
          ref={'mailInfoFooter'}
          href={'mailto:info@giavaneers.com'}
          style={{ display: 'none' }}
        >
          mail
        </a>
        <a
          ref={'phoneCallFooter'}
          href={'tel:18314599060'}
          style={{ display: 'none' }}
        >
          phone
        </a>
      </FooterAll>
    );
  }
}

function FooterAll(props) {
  const matches = useMediaQuery(`(min-width:${SMALL_THRESHOLD})`);
  if (matches || (props.title !== 'Contact' && props.title !== 'Home')) {
    return (
      <BottomNavigation className={props.classes.root} color={'primary'}>
        <FooterAddress text={'1545 1/2 Pacific Ave.'} {...props} />
        <FooterAddress text={'Santa Cruz, CA 95060'} {...props} />
        <FooterEmail {...props} />
        <FooterPhone {...props} />
        {props.children}
      </BottomNavigation>
    );
  } else {
    return null;
  }
}

function FooterAddress(props) {
  const matches = useMediaQuery(`(min-width:${SMALL_THRESHOLD})`);
  return (
    <Typography
      className={props.classes.link}
      onClick={props.goNavigate(ADDRESS_LINK)}
      // color="secondary"
      style={{ display: matches ? undefined : 'none' }}
      variant={typeVariant}
    >
      {props.text}
    </Typography>
  );
}

function FooterEmail(props) {
  return (
    <Typography
      // color="secondary"
      onClick={props.mailTo}
      className={props.classes.link}
      variant={typeVariant}
    >
      info@giavaneers.com
    </Typography>
  );
}

function FooterPhone(props) {
  return (
    <Typography
      // color="secondary"
      onClick={props.callPhone}
      className={props.classes.link}
      variant={typeVariant}
    >
      +1 (831) 459-9060
    </Typography>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
