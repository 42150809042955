import techOneImage from '../static/images/technologies/tech1.jpeg';
import techTwoImage from '../static/images/technologies/tech2.jpeg';
import techThreeImage from '../static/images/technologies/tech3.jpg';

export const TECHNOLOGIES = [
  {
    title: 'JavaFlow',
    description:
      'Use Java to build the same great TensorFlow machine learning applications that you do with Python. Like Red Hat for Linux, JavaFlow is a systems integration packaging the TensorFlow platform, a complete Java API with the same powerful features you get with the standard Python interface, a compatible version of TensorBoard, and a number of illustrative examples. Developing powerful models, training them and then using them on real world data is simple to write and easy to debug; packaged in a way that naturally combines the structure, familiarity, and reach of Java.',
    logo: techTwoImage,
    link: 'http://www.javaflow.io/',
    anchor: 'javaflow'
  },
  {
    title: 'MobiScope 5G',
    description:
      'MobiScope 5G is a highly scalable platform that supports millions of Android and iOS mobile devices concurrently reporting operational data to a cloud based server. Powerful data visualizations support both realtime and historical displays of network activity with the ability to isolate analysis of the user experience down to any particular network base station. Strict privacy guidelines are followed, guaranteeing complete anonymity of any individual device. A uniquely powerful Over the Air (OTA) distribution system keeps remote devices automatically up to date with firmware changes without user intervention. Complimenting the internal view from the network provider outward to its users, MobiScope 5G provides a view from mobile devices back into the network.',
    logo: techOneImage,
    link: 'http://www.mobiscope.io/',
    anchor: 'mobiscope5g'
  },
  {
    title: 'ReactJava',
    description:
      'Use Java to build the same great applications for mobile and the desktop as you do with React and React Native. The same powerful features of React you expect: lightweight, declarative, performant, component-based programming that is simple to write and easy to debug; packaged in a way that naturally combines the structure, familiarity, and reach of Java. And targeting native mobile environments is often right out of the box. In most cases, ReactJava automatically translates your ReactJava components to React Native equivalents.',
    logo: techTwoImage,
    link: 'http://www.reactjava.io/',
    anchor: 'reactjava'
  },
  {
    title: 'TurboTablets',
    description:
      'OpenL Tablets is a unique business rules engine that uses Excel spreadsheets to express the rules it contains. The intention is to allow regular business members, typically non-programmers, the ability to use tools they are already comfortable with to develop and maintain the rules that capture elements of their business processes, rather than relying on a team of software developers. TurboTablets expands this platform by providing utility functions which improve both performance and robustness of Spreadsheets and Decision Tables, and a set of \'best practices\' developed through research and experience which serve to simplify rules development and enhance rules performance.',
    logo: techThreeImage,
    link: 'http://www.turbotablets.io/',
    anchor: 'turbotablets'
  },
  {
    title: 'CanMart',
    description: 'CanMart is a highly scalable, configurable platform that supports a branded online marketplace for the sale and distribution of cannabis products. Administrator tools provide powerful data visualizations supporting both realtime and historical displays of sales activity with the ability to analyze the success of each user experience in detail. This uniquely powerful administration system keeps the changing inventory automatically up to date and makes the management of special offers and sales campaigns easy and effective. Strict privacy guidelines are followed, guaranteeing complete anonymity of any individual customer device. Complimenting an engaging marketplace experience for the network customer, CanMart provides a powerful toolset for easily managing the entire enterprise.',
    logo: techOneImage,
    link: 'http://www.canmart.io/',
    anchor: 'canmart'
  },
  {
    title: 'iWonder Marketplace',
    description: 'The iWonder Marketplace is a revolutionary media publication and licensing service which allows free public access to content marketing and distribution channels that up until now have been locked up by a relatively small number of largely inaccessible media giants. Much as the world-wide web has created a revolutionary expansion of public access to information, the iWonder Marketplace promises free and open access for artists across the world, those with large budgets and small, to get the same widespread distribution of their work, to reach audiences of incredible size, and to receive an unconventionally large share of the resulting revenue.',
    logo: techTwoImage,
    link: 'http://www.iwonderbundle.com/',
    anchor: 'iwonder'
  },
  {
    title: 'Marine Space Station',
    description:
      'The Marine Space Station is a gathering of a number of great public schools, a team of world-renowned scientists and engineers, and some of the finest science and engineering organizations in the world, all located near the Monterey Bay, one of the most spectacular marine environments on the planet. Its mission is to showcase the passion, creativity, and the startling capacity for achievement of our community high school students by providing a unique opportunity to engage in original research using state of the art marine science datasets.',
    logo: techThreeImage,
    link:
      'http://marinespacestation.org/opensource/MarineSpaceStationWebsite.html',
    anchor: 'marinespacestation'
  }
];
