import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NavList from './NavList';
import PropTypes from 'prop-types';

class NavMenu extends React.Component {
  state = {
    isOpen: false
  };

  toggleDrawer = open => () => {
    this.setState({
      isOpen: open
    });
  };

  render() {
    return (
      <div className={this.props.className}>
        <IconButton
          color="inherit"
          aria-label="Menu"
          onClick={this.toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor="right"
          open={this.state.isOpen}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            <NavList
              currentPage={
                this.props.currentPage
                  ? this.props.currentPage.title
                  : undefined
              }
              goRedirect={this.props.goRedirect}
            />
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

NavMenu.propTypes = {
  title: PropTypes.string.isRequired,
  goRedirect: PropTypes.func.isRequired
};

export default NavMenu;
