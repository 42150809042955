import React, { Component } from 'react';
import PartnersList from './PartnersList';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery/index';
import { SMALL_THRESHOLD } from '../../../constants/media';
import headerImage from '../../../static/images/highway-1-road-trip-scotts-creek-san-francisco-santa-cruz-via-magazine.jpg';
import Typography from '@material-ui/core/Typography';
import {Helmet} from "react-helmet";

const classes = theme => ({
  card: {
    maxWidth: '1400px',
    margin: 'auto',
    marginBottom: '62px',
    paddingTop: '62px'
  },
  media: {
    height: '350px'
  },
  mediaPhone: {
    height: '250px'
  }
});

const descriptionText =
  "Our customers include large, corporate multinationals, federal and state agencies, and a whole bunch of small, exciting, Silicon Valley startups. We make creative design and implementation contributions to our partners' success as members of their extended research and development team. We've included a few of our favorite projects here for you to take a look. If you see something you'd like to talk over with us, get in touch. We'd love to talk to you.";

class Partners extends Component {
  render() {
    return <Content {...this.props} />;
  }
}

function Content(props) {
  const { classes } = props;
  const matches = useMediaQuery(`(min-width:${SMALL_THRESHOLD})`);

  return (
    <Card className={classes.card}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Giavaneers Partners</title>
        <meta
          name="description"
          content="Our customers include large, corporate multinationals, federal and state agencies,
                   and a whole bunch of small, exciting, Silicon Valley startups. We would love to talk to you
                   about your project. Get in touch." />
      </Helmet>
      <CardMedia
        className={matches ? classes.media : classes.mediaPhone}
        image={`${headerImage}`}
        // title="Contact Us"
      />
      <CardContent>
        {/*<Typography gutterBottom variant="h5" component="h5">*/}
        {/*Partners & Projects*/}
        {/*</Typography>*/}
        {/*<Typography*/}
        {/*className={classes.contactMessage}*/}
        {/*variant="h6"*/}
        {/*component="p"*/}
        {/*>*/}
        {/*Here is a sample of some of our favorite projects.*/}
        {/*</Typography>*/}
        <Typography variant={'subtitle1'}>{descriptionText}</Typography>
        <PartnersList {...props} />
      </CardContent>
    </Card>
  );
}

export default withStyles(classes)(Partners);
