import React from 'react';
import Page from './widgets/page/Page';
import { PAGES, HOME, CONTACT, PARTNERS, PLATFORMS } from '../constants/pages';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactPixel from 'react-facebook-pixel';
import { THEME } from '../constants/theme';
import NavBar from './widgets/navbar/NavBar';
import 'babel-polyfill';
import ReactGA from 'react-ga';
import queryString from 'query-string'

const theme = createMuiTheme(THEME);

const App = () => (
  <Router>
    <Root />
  </Router>
);

const options = {
  autoConfig: true,
  debug: false
};

const advancedMatching = {};

class Root extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      route: '',
      redirect: false,
      navigate: false,
      url: ''
    };
                                      // google analytics configuration     //
    ReactGA.initialize('UA-28843953-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

                                      // facebook tracking for ads          //
    ReactPixel.init('2329240953795233', advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view

    this.goRedirect = this.goRedirect.bind(this);
    this.clearRedirect = this.clearRedirect.bind(this);
    this.goNavigate = this.goNavigate.bind(this);
  }

  componentDidUpdate() {
    if (this.state.navigate) {
      window.open(this.state.url, '_blank').focus();
      this.setState(() => ({
        navigate: false,
        url: ''
      }));
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <NavBar goRedirect={this.goRedirect} goNavigate={this.goNavigate} />
        <Switch>
        {PAGES.map(page => (
          <Route
            key={page.title}
            // exact={true}
            path={`${page.route}`}
            render={props => (
              <Page
                {...props}
                redirect={this.state.redirect}
                route={this.state.route}
                page={page}
                goRedirect={this.goRedirect}
                goNavigate={this.goNavigate}
                clearRedirect={this.clearRedirect}
              />
            )}
          />
        ))}
        <Route component={QueryParse} />
        {/*<Route exact={true} path="/" component={HomeRedirect} />*/}
        </Switch>
      </MuiThemeProvider>
    );
  }

  clearRedirect = () => {
    this.setState(() => ({
      redirect: false,
      route: ''
    }));
  };

  goRedirect = route => () => {
    this.setState(() => ({
      redirect: true,
      route: route
    }));
  };

  goNavigate = url => () => {
    this.setState(() => ({
      navigate: true,
      url: url
    }));
  };
}

const QueryParse = (props) => {
  const value=queryString.parse(props.location.search);
  const page=value.page;
  console.log(page);
  switch(page) {
    case 'partners':
      console.log('redirecting to partners');
      return <PartnersRedirect />;
    case 'platforms':
      console.log('redirecting to platforms');
      return <PlatformsRedirect />;
    case 'contact':
      console.log('redirecting to contact');
      return <ContactRedirect />;
    case 'home':
      console.log('redirecting to home');
      return <HomeRedirect />;
    default:
      console.log('redirecting to home');
      return <HomeRedirect />;
  }
}

const HomeRedirect = () => <Redirect to={HOME.route} />;
const ContactRedirect = () => <Redirect to={CONTACT.route} />;
const PartnersRedirect = () => <Redirect to={PARTNERS.route} />;
const PlatformsRedirect = () => <Redirect to={PLATFORMS.route} />;



export default App;
