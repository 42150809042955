import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery/index';
import { TECHNOLOGIES } from '../../../constants/technologies';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { SMALL_THRESHOLD } from '../../../constants/media';
import ScrollableAnchor from 'react-scrollable-anchor'
import {Helmet} from "react-helmet";

const classes = {
  card: {
    maxWidth: '900px',
    margin: 'auto',
    marginBottom: '25px'
  },
  media: {
    height: '300px'
  },
  mediaPhone: {
    height: '140px'
  },
  cardActions: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  }
};

class Platforms extends Component {
  render() {
    return <Body {...this.props} />;
  }
}

function Body(props) {
  const { classes, goNavigate } = props;
  const matches = useMediaQuery(`(min-width:${SMALL_THRESHOLD})`);

  return (
    <div style={{ paddingTop: '62px', paddingBottom: '62px' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Giavaneers Technologies</title>
        <meta
          name="description"
          content="We work with all of the cutting edge technologies, including Java, C++, C, Objective C, Python,
                   Javascript, Node, React, Angular, AI, Server side, Desktop Applications and Mobile Apps. We have also
                   developed custom technology platforms." />
      </Helmet>

      {TECHNOLOGIES.map(({ title, description, logo, link, anchor }) => (
        <ScrollableAnchor id={anchor}>
          <Card className={classes.card} key={title}>
            <CardActionArea onClick={link ? goNavigate(link) : undefined}>
              <CardMedia
                className={matches ? classes.media : classes.mediaPhone}
                image={`${logo}`}
                title={title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h5">
                  {title}
                </Typography>
                <Typography variant="body1" component="p">
                  {description}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <div className={classes.grow} />
                <Button
                  component="a"
                  href={`${link}`}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={(event) => {
                    event.preventDefault();
                    if (link) {
                      goNavigate(link)();
                    }
                  }}
                >
                  Learn More
                </Button>
              </CardActions>
            </CardActionArea>
          </Card>
        </ScrollableAnchor>
      ))}
    </div>
  );
}

Platforms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(classes)(Platforms);
