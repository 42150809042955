import Home from '../components/pages/home/Home';
import Contact from '../components/pages/contact/Contact';
import Partners from '../components/pages/partners/Partners';
import Platforms from '../components/pages/platforms/Platforms';

export const HOME = {
  title: 'Home',
  route: '/home',
  component: Home
};

export const CONTACT = {
  title: 'Contact',
  route: '/contact',
  component: Contact,
  index: 2
};

export const PARTNERS = {
  title: 'Partners',
  route: '/partners',
  component: Partners,
  index: 0
};

export const PLATFORMS = {
  title: 'Platforms',
  route: '/platforms',
  component: Platforms,
  index: 1
};

export const PAGES = [HOME, PARTNERS, PLATFORMS, CONTACT];
