import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PartnerCard from './PartnerCard';

class PartnerDialog extends React.Component {
  render() {
    const { open, onClose, fullScreen, partner } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <PartnerCard {...this.props} partner={partner} />
      </Dialog>
    );
  }
}

PartnerDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  partner: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withMobileDialog()(PartnerDialog);
