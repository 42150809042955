import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Footer from '../footer/Footer';

class Page extends React.Component {
  render() {
    const { title, component: PageComponent } = this.props.page;
    const { redirect, route, page } = this.props;

    if (redirect && page.route !== route) {
      this.props.clearRedirect();
      return <Redirect to={route} push={true} />;
    }

    return (
      <div color={'background'}>
        <PageComponent {...this.props} />
        <Footer {...this.props} title={title} />
      </div>
    );
  }
}

Page.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    index: PropTypes.number
  })
};

export default Page;
