import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import './PartnerCard.css';

const styles = {
  popupCard: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    paddingTop: '25px'
  },
  cardMedia: {
    height: '140px',
    backgroundSize: 'contain'
  },
  flexContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  // cardContent: {
  //   flex: 1
  // },
  cardButtons: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  }
};

class PartnerCard extends React.Component {
  render() {
    const { classes, partner } = this.props;

    return (
      <Card className={classes.popupCard}>
        <div className={classes.flexContainer}>
          <CardMedia
            className={classes.cardMedia}
            image={`${partner.logo}`}
            // title={partner.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {partner.title}
            </Typography>
            <Typography component="p">{partner.description}</Typography>
            <div style={{ height: '10px' }} />
            {partner.descriptionTwo && (
              <Typography component="p">{partner.descriptionTwo}</Typography>
            )}
            {/*<div className={classes.flex} />*/}
          </CardContent>
          <div className={classes.flex} />
          <CardActions className={classes.cardButtons}>
            {partner.url && (
              <Button
                component={'a'}
                href={ partner.url }
                className={classes.cardWebsiteButton}
                onClick= {(event) => {
                  event.preventDefault();
                  if (partner.url) {
                    this.props.goNavigate(partner.url)()
                  }
                }}
                color="primary"
                autoFocus
              >
                Visit Website
              </Button>
            )}
            <div className={classes.grow} />
            <Button
              className={classes.cardCloseButton}
              onClick={this.props.onClose}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </CardActions>
        </div>
      </Card>
    );
  }
}

PartnerCard.propTypes = {
  partner: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    logo: PropTypes.string,
    url: PropTypes.string
  }),
  classes: PropTypes.object.isRequired,
  goNavigate: PropTypes.func.isRequired
};

export default withStyles(styles)(PartnerCard);
