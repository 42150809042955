import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import homeImage from '../../../static/images/redwoods1.jpg';
import partnerImage from '../../../static/images/highway-1-road-trip-scotts-creek-san-francisco-santa-cruz-via-magazine.jpg';
import contactImage from '../../../static/images/bridges.jpg';
import platformImage from '../../../static/images/technologies/tech1.jpeg';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery/index';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CONTACT } from '../../../constants/pages';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import IconButton from '@material-ui/core/IconButton';

import { SMALL_THRESHOLD } from '../../../constants/media';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Fab from '@material-ui/core/Fab'
import {Helmet} from "react-helmet";

const classes = theme => ({
  card: {
    // maxWidth: '1400px',
    margin: 'auto'
    // marginBottom: '25px'
  },
  media: {
    height: '100vh'
  },
  slideContainer: {
    position: 'relative'
  },
  content: {
    borderRadius: '8px',
    background: 'rgba(0, 0, 0, 0.3)',
    padding: '10px',
    maxWidth: '645px',
    position: 'absolute',
    right: 40,
    top: 150
  },
  contentSmall: {
    borderRadius: '8px',
    background: 'rgba(0, 0, 0, 0.3)',
    padding: '10px',
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 80
  },
  contentTitle: {
    color: '#f9f9f9'
  },
  contentText: {
    paddingTop: '0.5em',
    paddingBottom: '1.25em',
    color: 'white'
  },
  contentTextMobile: {
    paddingTop: '0.5em',
    paddingBottom: '1.25em',
    color: 'white',
    lineHeight: '1.4'
  },
  contactButtonPhone: {
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  contactButtonOverride: {
     marginRight: '4px'
  },
  browserStepper: {
    background: 'rgba(0, 0, 0, 0)',
    position: 'fixed',
    bottom: '60px',
    width: '100%'
  },
  mobileStepper: {
    background: 'rgba(0, 0, 0, 0)',
    position: 'fixed',
    bottom: '0px',
    width: '100%'
  },
  stepButtonContainer: {
    float: 'right',
    marginTop: '24px'
  },
  stepButton: {
    marginRight: '10px'
  },
  learnMoreButton: {
    marginRight: '4px',
  },
  smallContactButton: {
    padding: '6px',
    marginRight: '4px',
    color: 'white',
    backgroundColor: '#003973',
    borderRadius: '4px'
  },
  regContactButton: {
    padding: '4px',
    marginRight: '4px',
    color: 'white',
    backgroundColor: '#003973',
    borderRadius: '4px'
  }

  // mediaPhone: {
  //   height:'140px'
  // },
});
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const slides = [
  {
    title: 'Creative Design',
    label:
      'We are a team of software developers in Santa Cruz. We build mobile apps, web apps, machine learning tools and custom cloud based servers.\n' +
      "And we've been providing great software at affordable prices for over 20 years. Our customers include large, corporate multinationals, federal and state agencies, and a whole bunch of small, exciting, Silicon Valley startups. We would love to talk to you about your project. Get in touch.",
    imgPath: `${homeImage}`
  },
  {
    title: 'Add to Your Team',
    label:
      "Our customers include large, corporate multinationals, federal and state agencies, and a whole bunch of small, exciting, Silicon Valley startups. We make creative design and implementation contributions to our partners' success as members of their extended research and development team. We've included a few of our favorite projects here for you to take a look. If you see something you'd like to talk over with us, get in touch. We'd love to talk to you.",
    imgPath: `${partnerImage}`,
    link: `/partners`
  },
  {
    title: 'Our Platforms',
    label:
      '\n' +
      "Sometimes we take it upon ourselves to develop a technology framework that we know will be of significant value to our existing and future customers. These are of our own invention, that we develop on our own nickel, and that our breadth of experience indicates meet a real market need. We've included a brief look at a few of these platforms for you to peruse. If you'd like to know more, we'd love to talk to you. Get in touch.",
    imgPath: `${contactImage}`,
    link: `/platforms`
  },
  {
    title: 'Get In Touch',
    label:
      "One of the best things about doing what we do is the great people we get to meet and interact with. Whether they be previous clients, or potential new ones, or people who just want to share an idea or talk about a new technology they've run across, it's great fun for us. Please feel free to get in touch anytime by phone or email or if you're in the area, just drop in and say hello.",
    imgPath: `${platformImage}`
  }
];

class Home extends Component {
  state = {
    activeStep: 0
  };

  mailTo = () => {
    this.refs.mailInfoFooter.click();
  };

  callPhone = () => {
    this.refs.phoneCallFooter.click();
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  // TODO: use theme spacing instead of hardcoded px
  render() {
    const { theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = slides.length;
    const springConfig = {
      duration: '0.6s',
      easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
      delay: '0s'
    };

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Giavaneers, Inc.</title>
          <meta
            name="description"
            content="We are a team of software developers in Santa Cruz. We build mobile apps, web apps, machine
                     learning tools and custom cloud based servers written in many software languages including Java,
                     Javascript, React, Angular and Ionic. We've been providing great software at affordable prices for
                     over 20 years. Let's build great software together." />
        </Helmet>
        <a
          ref={'mailInfoFooter'}
          href={'mailto:info@giavaneers.com'}
          style={{ display: 'none' }}
        >
          mail
        </a>
        <a
          ref={'phoneCallFooter'}
          href={'tel:18314599060'}
          style={{ display: 'none' }}
        >
          phone
        </a>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
          interval={20000}
          springConfig={springConfig}
        >
          {slides.map((step, index) => (
            <div key={index}>
              {Math.abs(activeStep - index) <= 5 ? (
                <SliderCard
                  step={step}
                  mailTo={this.mailTo}
                  callPhone={this.callPhone}
                  {...this.props}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <Stepper
          maxSteps={maxSteps}
          activeStep={activeStep}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          {...this.props}
        />
      </div>
    );
  }
}

function Stepper(props) {
  const { classes, maxSteps, activeStep, handleNext, handleBack } = props;
  const matches = useMediaQuery(`(min-width:${SMALL_THRESHOLD})`);

  return (
    <MobileStepper
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      className={matches ? classes.browserStepper : classes.mobileStepper}
      nextButton={
        <SliderButton
          matches={matches}
          clickFunction={handleNext}
          activeStep={activeStep}
          disabled={activeStep === maxSteps - 1}
          {...props}
        >
          <ArrowForward />
        </SliderButton>
      }
      backButton={
        <SliderButton
          matches={matches}
          clickFunction={handleBack}
          activeStep={activeStep}
          disabled={activeStep === 0}
          {...props}
        >
          <ArrowBack />
        </SliderButton>
      }
    />
  );
}

function SliderCard(props) {
  const { classes, step } = props;
  const matches = useMediaQuery(`(min-width:${SMALL_THRESHOLD})`);

  return (
    <div className={classes.slideContainer}>
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={step.imgPath} />
      </Card>
      <div className={matches ? classes.content : classes.contentSmall}>
        <Typography
          className={classes.contentTitle}
          variant={matches ? 'h3' : 'h4'}
        >
          {step.title}
        </Typography>{' '}
        <Typography
          className={matches ? classes.contentText : classes.contentTextMobile}
          variant={matches ? 'h6' : 'subtitle1'}
        >
          {step.label}
        </Typography>
        <div className={classes.stepButtonContainer}>
          {props.step.link && <LearnMoreButton matches={matches} {...props} />}
          <ContactButton matches={matches} {...props} />
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              props.callPhone();
            }}
            component={'a'}
            href={'tel:18314599060'}
            aria-label="Delete"
            className={matches ? classes.regContactButton : classes.smallContactButton}
          >
            <Phone fontSize={matches ? 'large' : undefined} />
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              props.mailTo();
            }}
            component={'a'}
            href={'mailto:info@giavaneers.com'}
            aria-label="Delete"
            className={matches ? classes.regContactButton : classes.smallContactButton}
          >
            <Email fontSize={matches ? 'large' : undefined} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

function LearnMoreButton(props) {
  const { matches, classes } = props;
  return (
    <Button
      component="a"
      href={`${window.location.protocol}//${window.location.host}${props.step.link}`}
      className={classes.learnMoreButton}
      variant="contained"
      color="primary"
      size={matches ? 'large' : undefined}
      onClick={(event) => {
        event.preventDefault();
        props.goRedirect(props.step.link)();
      }}
    >
      Learn More
    </Button>
  );
}

function ContactButton(props) {
  const { matches, classes } = props;
  return (
    <Button
      component="a"
      className={classes.contactButtonOverride}
      variant="contained"
      color="primary"
      size={matches ? 'large' : undefined}
      href={`${window.location.protocol}//${window.location.host}${CONTACT.route}`}
      onClick={(event) => {
        event.preventDefault();
        props.goRedirect(CONTACT.route)();
      }}
    >
      Get in Touch
    </Button>
  );
}

function SliderButton(props) {
  const { matches, clickFunction, disabled } = props;

  if (matches) {
    return (
      <Fab
        style={{ display: matches ? undefined : 'none' }}
        onClick={clickFunction}
        disabled={disabled}
        color="primary"
      >
        {props.children}
      </Fab>
    );
  } else {
    return <div />;
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(classes, { withTheme: true })(Home);
