import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { PAGES } from '../../../constants/pages';
import PropTypes from 'prop-types';

class NavList extends React.Component {
  render() {
    const iconList = PAGES.filter(page => page.navIcon).map(
      ({ route, title, navIcon: NavIcon }) => (
        <ListItem
          key={title}
          disabled={title === this.props.currentPage}
          button
          onClick={this.props.goRedirect(route)}
        >
          <ListItemIcon>
            <NavIcon />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      )
    );

    const noIconList = PAGES.filter(page => !page.navIcon).map(
      ({ route, title }) => (
        <ListItem
          key={title}
          disabled={title === this.props.currentPage}
          button
          onClick={this.props.goRedirect(route)}
        >
          <ListItemText primary={title} />
        </ListItem>
      )
    );

    return (
      <div style={{ width: '250px' }}>
        <List component="nav">
          {iconList}
          {iconList.length !== 0 && noIconList.length !== 0 && <Divider />}
          {noIconList}
        </List>
      </div>
    );
  }
}

NavList.propTypes = {
  currentPage: PropTypes.string.isRequired,
  goRedirect: PropTypes.func.isRequired
};

export default NavList;
